import dashboardApi from 'apis/disApi/dashboardApi'
import tileApi from 'apis/disApi/tileApi'
import moment from 'moment'
import { showErrorMessage } from 'utils/functions/helpers'

export function sortByPosition(list) {
	if (!Array.isArray(list) || list?.length === 0 || !list) return []

	let sortedList = [...list].sort((a, b) => +a.position - +b.position)

	return sortedList
}

export function transformStringToPositionArr(position) {
	// Loại bỏ các ký tự không phải số từ chuỗi
	var cleanStr = position.replace(/[^\d,-]/g, '')

	// Chuyển đổi chuỗi thành mảng
	var arr = cleanStr.split(',')

	// Chuyển đổi các phần tử trong mảng thành số nguyên
	var result = arr.map(function (item) {
		return parseInt(item.trim())
	})

	return result
}

// Function tìm chỗ trống trong layout để đặt box mới
export function getEmptyPosition(layoutList) {
	let maxX = 0
	let maxY = 0
	for (const item of layoutList) {
		const itemRightX = item.x + item.w
		const itemBottomY = item.y + item.h
		if (itemRightX > maxX) maxX = itemRightX
		if (itemBottomY > maxY) maxY = itemBottomY
	}

	return { x: maxX, y: maxY }
}

export function getTileListWithPosition(tileList) {
	if (tileList?.length === 0) return []
	return tileList.map((tile) => {
		const [x, y, w, h] = transformStringToPositionArr(tile.position)
		return {
			...tile,
			i: tile.name,
			x,
			y,
			w,
			h,
		}
	})
}

export function generateRandomID() {
	return Math.random().toString(36).substring(2)
}

export function getAutoUpdateFilters(linkedFields, selectedFields, dashboardKeyList) {
	try {
		if (linkedFields?.length === 0 || selectedFields?.length === 0) return []

		const result = []
		let hasPrimaryKey = false

		for (let fieldId in linkedFields) {
			const selectedDashboardKey = dashboardKeyList.find((item) => item.id === fieldId)
			const isHasPrimaryKey = Boolean(selectedDashboardKey)
			const filteredValue =
				selectedFields?.[selectedDashboardKey?.tileKey]?.[selectedDashboardKey?.key]

			if (filteredValue && isHasPrimaryKey) {
				hasPrimaryKey = true
				result.push({
					field: linkedFields[fieldId],
					operator: 'contains',
					value: filteredValue,
					id: generateRandomID(),
				})
			}
		}
		return { filterConfigs: result, hasPrimaryKey }
	} catch {
		return { filterConfigs: [], hasPrimaryKey: false }
	}
}

export const processCreateTilesOfNewMenu = async ({ copiedMenu, dashboardResponse, token }) => {
	const copiedDashboard = await dashboardApi.get(copiedMenu?.dashboard?.key, token)

	if (copiedDashboard?.data?.tiles?.length > 0) {
		const copiedTiles = copiedDashboard?.data?.tiles.map((tile) => ({
			...tile,
			// Update dashboard key of copied menu
			key: undefined,
			dashboardKey: dashboardResponse.data.key,
		}))

		copiedTiles.forEach((dataItem) => {
			tileApi.add({
				dashboardKey: dashboardResponse.data.key,
				data: dataItem,
				token,
			})
		})
	}
}

export const createItemsOfSubmenu = async (copiedMenu, token) => {
	try {
		if (!copiedMenu?.items) return []

		const itemsOfSubmenu = copiedMenu?.items?.map((item) => ({
			...item,
			name: item.name,
			position: item.position,
			dashboard: item.dashboard,
		}))

		const result = []

		for (const dataItem of itemsOfSubmenu) {
			const newDashboard = {
				...dataItem,
				name: dataItem.name,
				position: dataItem.position,
			}

			try {
				const dashboardResponse = await dashboardApi.add(newDashboard, token)

				if (dashboardResponse.data) {
					const newSubMenu = {
						...newDashboard,
						dashboard: { key: dashboardResponse.data.key },
					}

					await processCreateTilesOfNewMenu({
						copiedMenu: dataItem,
						dashboardResponse,
						token,
					})

					result.push(newSubMenu)
				}
			} catch (error) {
				showErrorMessage(error)
			}
		}

		return result
	} catch {
		return []
	}
}
