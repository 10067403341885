import { LoadingButton } from '@mui/lab'
import {
	Alert,
	AlertTitle,
	Autocomplete,
	Box,
	Button,
	Checkbox,
	Container,
	CssBaseline,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	Grid,
	Link,
	Slide,
	Snackbar,
	TextField,
	ThemeProvider,
	Typography,
	createTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ENV_ACTIONS } from '../../reducers/environmentReducer'
import poweredByLogo from '../../static/img/powered_by_doforms.png'
import { authenticate } from '../auth/AuthServices'
import RecoverAccount from './RecoverAccount'
import { accountRecovering, userSignIn } from './userServices'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	buttonsGroup: {
		marginTop: '8px',
	},
	logo: {
		width: '8em',
	},
	signInButton: {
		'&:hover': {
			backgroundColor: '#F86900',
			color: '#fff',
		},
	},
	selectPortalButton: {
		textTransform: 'none',
	},
	cancelButton: {
		textTransform: 'none',
	},
}))

const theme = createTheme()

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const SignIn = () => {
	const classes = useStyles()
	const [t] = useTranslation('common')
	const dispatch = useDispatch()
	const { environment } = useSelector((state) => state)

	const loginSpecifyAccount = localStorage.getItem('sign_in_specify_account') !== 'false'

	const initialState = {
		user: null,
		username: '',
		password: '',
		account: null,
		code: null,
		// error: null,
		// warning: null,
		isAuthenticated: false,
	}
	const [values, setValues] = useState(initialState)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [warning, setWarning] = useState(null)

	const [userData, setUserData] = useState([])
	const [accounts, setAccounts] = useState([])
	const [showAccount, setShowAccount] = useState(loginSpecifyAccount)
	const [showCode, setShowCode] = useState(false)
	const [recoverAccount, setRecoverAccount] = useState(false)
	const [showSelectPortal, setShowSelectPortal] = useState(false)
	const [loginOldPortalToast, setLoginOldPortalToast] = useState(false)

	const selectNewPortalBtnRef = useRef(null)

	// const {user, username, password, account, code, error, warning, loading, isAuthenticated} = values;
	const { user, username, password, account, code, isAuthenticated } = values

	const enabled = username.length > 0 && password.length > 0

	useEffect(() => {
		if (!isAuthenticated) return
		authenticate(user)
		dispatch({
			type: ENV_ACTIONS.GET_USER,
			payload: {
				user: user,
			},
		})
	}, [user])

	const showError = useCallback(
		() =>
			error ? (
				<Alert
					severity="error"
					onClose={() => {
						setError(null)
					}}
				>
					{error}
				</Alert>
			) : null,
		[error]
	)

	const showWarning = useCallback(
		() =>
			warning ? (
				<Alert
					severity="warning"
					onClose={() => {
						setWarning(null)
					}}
				>
					{warning}
				</Alert>
			) : null,
		[warning]
	)

	// <Alert severity="error" onClose={() => {setValues({...values, error: null})}}>Error</Alert>

	// const showWarning = () =>
	//     warning && <Alert severity="warning" onClose={() => {setValues({...values, warning: null})}}>{warning}</Alert>

	const onAccountChange = (event, results) => {
		setValues({ ...values, account: results })
	}

	const onAccountInputChange = (event, newInputValue, reason) => {
		if (reason === 'reset' || reason === 'clear') {
			setValues({ ...values, account: null })
			return
		} else {
			setValues({ ...values, account: newInputValue })
		}
	}

	const toggleShowAccount = () => {
		localStorage.setItem('sign_in_specify_account', !showAccount)
		setShowAccount(!showAccount)
	}

	// Handlers
	const handleRecoverAccount = () => {
		setRecoverAccount(true)
	}

	const recoverAccountClosed = () => {
		setRecoverAccount(false)
	}

	const handleChange = (name) => (event) => {
		setValues({ ...values, [name]: event.target.value })
	}

	const handleClear = (event) => {
		event.preventDefault()
		setValues(initialState)
		setAccounts([])
		setShowAccount(false)
		setShowCode(false)
	}

	const recoverAccountConfirmed = (event) => {
		event.preventDefault()
		const usernameValue = event.target.username

		setLoading(true)
		setValues({ ...initialState })
		accountRecovering(usernameValue)
			.then(() => {
				setWarning(t('common:signIn.recoveredAccountMessage'))
				recoverAccountClosed()
			})
			.catch((err) => {
				setError(`Error ${err.response.status}. Please check your username again and retry.`)
				recoverAccountClosed()
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const goToOldPortal = (user = null) => {
		window.location.href = `${environment.renderHost}?token=${(user || userData).token}`
	}

	const goToNewPortal = (user = null) => {
		setValues({ ...values, user: user || userData, isAuthenticated: true })
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		setWarning(null)
		setError(null)
		setLoading(true)
		userSignIn({ username: username?.trim(), password, account, code })
			.then((res) => {
				const { loginNewPortal, loginOldPortal } = res.data
				setUserData(res.data)
				if (loginNewPortal) {
					goToNewPortal(res.data)
				} else if (!loginNewPortal && loginOldPortal) {
					setLoginOldPortalToast(true)
				}
			})
			.catch((err) => {
				console.log(err)
				if (err.response.data.code === 300) {
					setShowAccount(true)
					setAccounts(_.sortBy(err.response.data.accounts))
					setWarning(t('common:signIn.includeAccount'))
				} else if (err.response.data.code === 968) {
					setShowCode(true)
					setWarning(t('common:signIn.enterCode'))
				} else {
					setError(err.response.data.message)
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleClose = () => {
		setShowSelectPortal(false)
	}

	const handleSelectPortal = (event, value) => {
		event.preventDefault()
		if (value === 'old') {
			goToOldPortal()
		} else {
			goToNewPortal()
		}
	}

	const handleKeyUpSelectPortalDialog = (event) => {
		if (event.key === 'Enter') {
			selectNewPortalBtnRef.current.click()
		}
		if (event.key === 'Escape') {
			handleClose()
		}
	}

	const renderSelectPortalDialog = () => (
		<Dialog
			open={showSelectPortal}
			onKeyUp={handleKeyUpSelectPortalDialog}
			onClose={handleClose}
			TransitionComponent={Transition}
			aria-labelledby="select-portal-dialog-title"
			aria-describedby="select-portal-dialog-description"
		>
			<DialogTitle id="select-portal-dialog-title">{t('signIn.selectPortalMessage')}</DialogTitle>
			<DialogContent>
				<DialogContentText component="div" id="select-portal-dialog-description">
					<Grid
						container
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
						spacing={1}
						className={classes.buttonsGroup}
					>
						<Grid item xs={6}>
							<Button
								ref={selectNewPortalBtnRef}
								className={classes.selectPortalButton}
								variant="contained"
								size="large"
								color="inherit"
								fullWidth
								onClick={(e) => handleSelectPortal(e, 'new')}
								autoFocus
							>
								Workforce Manager portal.doforms.com
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								className={classes.selectPortalButton}
								variant="contained"
								size="large"
								color="inherit"
								fullWidth
								onClick={(e) => handleSelectPortal(e, 'old')}
							>
								Administrative site mydoforms.appspot.com
							</Button>
						</Grid>
					</Grid>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button className={classes.cancelButton} onClick={handleClose}>
					{t('signIn.cancel')}
				</Button>
			</DialogActions>
		</Dialog>
	)

	const closeOldPortalToast = () => {
		setLoginOldPortalToast(false)
	}

	const openOldPortalLink = () => {
		goToOldPortal()
		setLoginOldPortalToast(false)
	}

	return (
		<>
			<ThemeProvider theme={theme}>
				<Container component="main" maxWidth="xs" className={classes.root}>
					{loginOldPortalToast && (
						<Snackbar
							open={loginOldPortalToast}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							style={{ height: '20%' }}
							onClose={closeOldPortalToast}
						>
							<Alert onClose={closeOldPortalToast} severity="info">
								<AlertTitle>Info</AlertTitle>
								Please log in at{' '}
								<Typography
									sx={{
										textDecoration: 'underline',
										display: 'inline-block',
										'&:hover': { opacity: 0.9, cursor: 'pointer' },
									}}
									onClick={openOldPortalLink}
								>
									mydoforms.com
								</Typography>
							</Alert>
						</Snackbar>
					)}
					<CssBaseline />
					<Box
						sx={{
							marginTop: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Typography component="h1" variant="h5">
							{t('common:nav.signIn')}
						</Typography>
						<Box mt={2}>
							{showError()}
							{showWarning()}
						</Box>
						<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
							<TextField
								value={username}
								onChange={handleChange('username')}
								fullWidth
								margin="normal"
								id="username"
								label={t('common:signIn.username')}
								name="username"
								autoComplete="username"
								required
								autoFocus
							/>
							<TextField
								value={password}
								onChange={handleChange('password')}
								margin="normal"
								required
								fullWidth
								name="password"
								label={t('common:signIn.password')}
								type="password"
								id="password"
								autoComplete="password"
							/>
							{showCode && (
								<TextField
									value={code}
									onChange={handleChange('code')}
									variant="outlined"
									margin="normal"
									fullWidth
									name="code"
									label={t('common:signIn.code')}
									id="code"
								/>
							)}
							{showAccount && (
								<Autocomplete
									id="account"
									freeSolo
									options={accounts.map((option) => option)}
									fullWidth
									onChange={onAccountChange}
									onInputChange={onAccountInputChange}
									sx={{ mt: 2 }}
									renderInput={(params) => (
										<TextField {...params} label={t('common:signIn.account')} />
									)}
								/>
							)}
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											value="specifyAccount"
											color="primary"
											checked={showAccount}
											onChange={toggleShowAccount}
										/>
									}
									label={t('common:signIn.specifyAccount')}
								/>
							</FormGroup>

							<Grid
								container
								direction="row"
								justifyContent="flex-end"
								alignItems="center"
								spacing={1}
								className={classes.buttonsGroup}
							>
								<Grid item xs={6}>
									<LoadingButton
										type="submit"
										variant="contained"
										loading={loading}
										className={classes.signInButton}
										color="inherit"
										disabled={!enabled}
										fullWidth
									>
										{t('common:nav.signIn')}
									</LoadingButton>
								</Grid>
								<Grid item xs={6}>
									<Button
										id="clearButton"
										variant="contained"
										color="inherit"
										onClick={handleClear}
										fullWidth
									>
										{t('common:signIn.clear')}
									</Button>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								justifyContent="flex-end"
								alignItems="center"
								sx={{ mt: 2 }}
							>
								<Grid item xs={12}>
									<Button variant="text" onClick={handleRecoverAccount} fullWidth>
										{t('common:signIn.forgotPassword')}
									</Button>
								</Grid>
							</Grid>
							<Grid
								container
								direction="row"
								justifyContent="flex-end"
								alignItems="center"
								sx={{ mt: 2 }}
							>
								<Grid item xs={12}>
									<br />
								</Grid>
							</Grid>
							<Grid container style={{ justifyContent: 'space-around' }}>
								<Grid item xs={6} style={{ textAlign: 'center' }}>
									<Link target="_blank" href="https://www.doforms.com">
										<img
											id="doformsLogo"
											alt="doformsLogo"
											src={poweredByLogo}
											className={classes.logo}
										/>
									</Link>
								</Grid>
							</Grid>
						</Box>
					</Box>
					{/*<Box mt={8}>*/}
					{/*    <Grid container spacing={2} style={{justifyContent: "space-around"}}>*/}
					{/*        <Grid item xs={4} style={{textAlign: "center"}}>*/}
					{/*            <Link target="_blank" href="https://www.doforms.com">*/}
					{/*                <img id="doformsLogo" alt="doformsLogo" src={poweredByLogo} className={classes.logo}/>*/}
					{/*            </Link>*/}
					{/*        </Grid>*/}
					{/*    </Grid>*/}
					{/*</Box>*/}
					{recoverAccount && (
						<RecoverAccount
							open={recoverAccount}
							loading={loading}
							onClose={recoverAccountClosed}
							onConfirmed={recoverAccountConfirmed}
						/>
					)}
					{renderSelectPortalDialog()}
				</Container>
			</ThemeProvider>
		</>
	)
}

export default SignIn
