import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { Alert, AlertTitle, Snackbar, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { VIEWS_ACTIONS } from '../reducers/viewsReducer'
import { ENV_ACTIONS } from '../reducers/environmentReducer'

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
	},
}))

const DoformsMessage = ({ linkViewInfo, message, severity, onMessageClosed }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const history = useHistory()
	const [open, setOpen] = useState(true)

	const handleClose = () => {
		setOpen(false)
		onMessageClosed()
	}

	const handleOpenViewLink = () => {
		dispatch({
			type: VIEWS_ACTIONS.VIEW_SELECTED,
			payload: linkViewInfo,
		})
		dispatch({
			type: VIEWS_ACTIONS.ACTION,
			payload: 'audit',
		})
		dispatch({
			type: ENV_ACTIONS.HAS_NEW_VIEW,
			payload: true,
		})
		history.push('/views')
	}

	useEffect(() => {
		// if(message) {
		//     setTimeout(() => handleClose(), 3000)
		// }
	}, [message])

	return (
		<div className={classes.root}>
			<Snackbar
				open={open}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				autoHideDuration={10000}
				style={{ height: '20%' }}
				onClose={handleClose}
			>
				<Alert onClose={handleClose} severity={severity}>
					<AlertTitle>{_.capitalize(severity)}</AlertTitle>
					{message}
					{linkViewInfo?.name && (
						<Typography
							sx={{
								textDecoration: 'underline',
								display: 'inline-block',
								'&:hover': { opacity: 0.9, cursor: 'pointer' },
							}}
							onClick={handleOpenViewLink}
						>
							{linkViewInfo?.name}
						</Typography>
					)}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default DoformsMessage
