const lngOptions = () => {
	return [
		{
			name: 'English',
			value: 'en',
		},
		{
			name: 'French',
			value: 'fr',
		},
		{
			name: 'Greek',
			value: 'el',
		},
		{
			name: 'Indonesian',
			value: 'id',
		},
		{
			name: 'Italian',
			value: 'it',
		},
		{
			name: 'Japanese',
			value: 'ja',
		},
		{
			name: 'Portuguese',
			value: 'pt',
		},
		{
			name: 'Russian',
			value: 'ru',
		},
		{
			name: 'Serbian',
			value: 'sr',
		},
		{
			name: 'Spanish',
			value: 'es',
		},
		{
			name: 'Vietnamese',
			value: 'vi',
		},
		{
			name: 'Hebrew',
			value: 'he',
		},
	]
}

export default lngOptions
