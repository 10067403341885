import React from 'react'
import { makeStyles } from '@mui/styles'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@mui/material'

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		zIndex: '99999',
	},
	icon: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		zIndex: '99999',
	},
}))

const LoadingSpinner = ({ withStyle, size }) => {
	const classes = useStyles()
	const iconsTheme = useSelector((state) => state.environment.iconsTheme)
	return (
		<div className={withStyle === false ? null : classes.root}>
			<CircularProgress
				className={withStyle === false ? null : classes.icon}
				size={size ? size : 40}
				style={{
					color: iconsTheme[0]
						? iconsTheme[0].color
							? iconsTheme[0].color
							: '#F86900'
						: '#F86900',
				}}
			/>
		</div>
	)
}

export default LoadingSpinner
