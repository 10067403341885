import { LoadingButton } from '@mui/lab'
import { Box, Stack, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import mapApi from 'apis/disApi/mapApi'
import MapLoginForm from 'components/pages/dis/tile/map/MapLoginForm'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getMapLoggedInInfoFromStorage, showErrorMessage } from 'utils/functions/helpers'
import { MAP_LOGGED_IN_INFO } from '../constants'

function DoformsMapLogin() {
	const [t] = useTranslation('common')
	const environment = useSelector((state) => state.environment)
	const history = useHistory()
	const location = useLocation()

	const [open, setOpen] = useState(false)
	const [formValues, setFormValues] = useState({
		username: '',
		password: '',
		account: '',
	})
	const [errorMsg, setErrorMsg] = useState('')
	const [isLogging, setIsLogging] = useState(false)

	// get user info from storage
	const userInfo = getMapLoggedInInfoFromStorage()

	const isLoggedIn = !_.isEmpty(userInfo)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFormValues((prev) => ({ ...prev, [name]: value }))
	}

	const handleLoginForm = async (e) => {
		try {
			e.preventDefault()

			if (!formValues.username || !formValues.password || !formValues.account) {
				setErrorMsg(t('common:dis.pleaseFillInformation'))
				return
			}

			const newUserInfo = {
				userName: formValues.username,
				password: formValues.password,
				account: {
					code: environment?.account?.code ?? 'dftest',
				},
				database: formValues.account,
			}

			setErrorMsg('')
			setIsLogging(true)
			const response = await mapApi.login(newUserInfo)
			const userValues = response?.data
			handleClose()

			if (!userValues) return
			localStorage.setItem(MAP_LOGGED_IN_INFO, JSON.stringify(userValues))

			const searchParams = new URLSearchParams(location.search)
			searchParams.delete('isMapLogout')
			history.push({
				pathname: location.path,
				search: searchParams.toString(),
			})
		} catch (error) {
			showErrorMessage(error)
			setErrorMsg(error?.message)
		} finally {
			setIsLogging(false)
		}
	}

	const handleLogout = async () => {
		setIsLogging(true)
		localStorage.removeItem(MAP_LOGGED_IN_INFO)

		const searchParams = new URLSearchParams(location.search)
		searchParams.set('isMapLogout', true)
		history.push({
			pathname: location.path,
			search: searchParams.toString(),
		})
		setIsLogging(false)
	}

	return (
		<Box sx={{ pb: 2, width: '100%' }}>
			<Typography variant={'subtitle1'}>Geotab</Typography>
			{!isLoggedIn ? (
				<>
					<Button variant="contained" onClick={handleClickOpen}>
						Login
					</Button>
					<Dialog
						open={open}
						onClose={handleClose}
						fullWidth={true}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<MapLoginForm
							isLoading={isLogging}
							onSubmit={handleLoginForm}
							onChangeInput={handleInputChange}
							errorMsg={errorMsg}
						/>
					</Dialog>
				</>
			) : (
				<Stack direction="row" alignItems="center" justifyContent="space-between">
					<Typography>{userInfo?.credentials?.userName}</Typography>
					<LoadingButton
						variant="outlined"
						onClick={handleLogout}
						disabled={isLogging}
						loading={isLogging}
					>
						Logout
					</LoadingButton>
				</Stack>
			)}
		</Box>
	)
}

export default DoformsMapLogin
