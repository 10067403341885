import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { signout } from './userServices'
import LoadingSpinner from '../../custom-components/LoadingSpinner'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '@mui/material'
import { ENV_ACTIONS } from '../../reducers/environmentReducer'
import { DEVICES_ACTIONS } from '../../reducers/devicesReducer'
import { FORM_ACTIONS } from '../../reducers/formsReducer'
import { LOOKUPS_ACTIONS } from '../../reducers/lookupsReducer'
import { VIEWS_ACTIONS } from '../../reducers/viewsReducer'
import { REPORTS_ACTIONS } from '../../reducers/reportsReducer'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},

	paper: {
		position: 'absolute',
		padding: '16px 32px 24px',
		borderRadius: '5px',
	},
}))

const Signout = (props) => {
	const [t] = useTranslation('common')
	const { open, theme, translations, source, onClose } = props
	const history = useHistory()

	const classes = useStyles(theme.topBar)

	const [loading, setLoading] = useState(false)

	const buttonStyle = [
		{ color: theme.icons.color },
		{
			'&:hover': {
				color: theme.icons.active.color,
			},
		},
	]

	const dispatch = useDispatch()

	const handleClose = (e, reason) => {
		if (reason === 'backdropClick' || reason === 'escapeKeyDown') return
		onClose()
	}

	const showLoading = () => loading && <LoadingSpinner />

	const clickSignout = (event) => {
		event.preventDefault()
		setLoading(true)
		signout(() => {
			dispatch({
				type: ENV_ACTIONS.SIGN_OUT,
			})
			dispatch({
				type: DEVICES_ACTIONS.RESET,
			})
			dispatch({
				type: FORM_ACTIONS.RESET,
			})
			dispatch({
				type: LOOKUPS_ACTIONS.RESET,
			})
			dispatch({
				type: VIEWS_ACTIONS.RESET,
			})
			dispatch({
				type: REPORTS_ACTIONS.RESET,
			})
			setLoading(false)
		})
		history.push('/')
	}

	const timeoutContent = () => {
		return (
			<div>
				<p>
					<b>{t('signOut.sessionExpired')}</b>
				</p>
				<Button sx={buttonStyle} onClick={clickSignout}>
					{t('misc.close')}
				</Button>
			</div>
		)
	}

	const defaultContent = () => {
		return (
			<div>
				<p>{t('signOut.messageConfirm')}</p>
				<Button sx={buttonStyle} onClick={clickSignout}>
					{translations.lbSignOut ? translations.lbSignOut : t('nav.signOut')}
				</Button>
				<Button sx={buttonStyle} onClick={handleClose}>
					{translations.btCancel ? translations.btCancel : t('misc.cancel')}
				</Button>
			</div>
		)
	}

	const modalBody = (
		<div className={classes.paper} style={{ backgroundColor: 'white' }}>
			{source === 'timeout' ? timeoutContent() : defaultContent()}
			{showLoading()}
		</div>
	)

	return (
		<div>
			<Modal open={open} onClose={handleClose} className={classes.root}>
				{modalBody}
			</Modal>
			{/*{source === "userClick"? renderUserClickModal() : renderTimeOutModal()}*/}
		</div>
	)
}

export default Signout
