import { StyledEngineProvider } from '@mui/material/styles'
import i18next from 'i18next'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { createStore } from 'redux'
import reducers from './reducers'
import common_el from './translations/el/common.json'
import common_en from './translations/en/common.json'
import common_es from './translations/es/common.json'
import common_fr from './translations/fr/common.json'
import common_he from './translations/he/common.json'
import common_id from './translations/id/common.json'
import common_it from './translations/it/common.json'
import common_ja from './translations/ja/common.json'
import common_pt from './translations/pt/common.json'
import common_ru from './translations/ru/common.json'
import common_sr from './translations/sr/common.json'
import common_vi from './translations/vi/common.json'

import { Provider } from 'react-redux'

import { LicenseInfo as LicenseInfoPremium } from '@mui/x-data-grid-premium'
import { LoadScript } from '@react-google-maps/api'
import App from './App'
import { DATAGRIDPREMIUMKEY, MAPKEY } from './config'

const enableReduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__?.()
const store = createStore(reducers, enableReduxDevTools)
const language = navigator.language.substring(0, 2)

//Set Material UI data grid premium license key
LicenseInfoPremium.setLicenseKey(`${DATAGRIDPREMIUMKEY}`)

//Set all translated languages from Babel edit (label.babel file) into common.json files to be dynamically used
i18next.init({
	interpolation: { escapeValue: false }, // React already does escaping
	lng: language, // default language from browser
	resources: {
		en: { common: common_en }, //English
		es: { common: common_es }, //Spanish
		el: { common: common_el }, //Greek
		fr: { common: common_fr }, //French
		id: { common: common_id }, //Indonesian
		it: { common: common_it }, //Italian
		pt: { common: common_pt }, //Portuguese
		ru: { common: common_ru }, //Russian
		sr: { common: common_sr }, //Serbian
		ja: { common: common_ja }, //Japanese
		vi: { common: common_vi }, //Vietnamese
		he: { common: common_he },
	},
})

ReactDOM.render(
	<Provider store={store}>
		<I18nextProvider i18n={i18next}>
			<StyledEngineProvider injectFirst>
				<LoadScript id="script-loader" googleMapsApiKey={`${MAPKEY}`}>
					<App id="app" />
				</LoadScript>
			</StyledEngineProvider>
		</I18nextProvider>
	</Provider>,
	document.getElementById('root')
)
