import axios from 'axios'
import { apiUrl } from '../apiUrl'

const url = apiUrl()
const apiHeader = (token) => {
	return {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}
}

const dashboardApi = {
	get(id, token, cancelToken) {
		return axios.get(`${url}/dashboards/${id}`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},

	assignWithTab(id, dashboardKey, token) {
		return axios.put(
			`${url}/tabs/${id}/dashboard`,
			{ key: dashboardKey },
			{
				headers: apiHeader(token),
			}
		)
	},

	add(data, token) {
		return axios.post(`${url}/dashboards`, data, {
			headers: apiHeader(token),
		})
	},

	update({ key, data, token }) {
		return axios.put(`${url}/dashboards/${key}`, data, {
			headers: apiHeader(token),
		})
	},

	remove(dashboardKey, token) {
		return axios.delete(`${url}/dashboards/${dashboardKey}`, {
			headers: apiHeader(token),
		})
	},
}

export default dashboardApi
