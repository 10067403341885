import { getApiHost } from '../core/services/coreServicesHelper'
import axios from 'axios'

const url = getApiHost()

export const userSignIn = async (user) => {
	const config = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	}

	return await axios.post(`${url}/tokens/user`, user, config)
}

export const accountRecovering = async (val) => {
	const config = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	}
	const bodyParameters = {
		username: val,
	}

	return await axios.post(`${url}/credentials/recover`, bodyParameters, config)
}

export const signin = (user) => {
	return fetch(`${url}/tokens/user`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(user),
	})
}

export const signout = (next) => {
	if (typeof window !== 'undefined') {
		sessionStorage.removeItem('jwt')
	}
	next()
}
