import ConstructionIcon from '@mui/icons-material/Construction'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import SettingsIcon from '@mui/icons-material/Settings'
import VideocamIcon from '@mui/icons-material/Videocam'
import { useQuery } from '@tanstack/react-query'
import menuApi from 'apis/disApi/menuApi'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { checkAdminRole, isJson } from 'utils/functions/helpers'
import DisNavTabs from '../../core/Layouts/DisNavTabs'
import NavLayout from '../../core/Layouts/NavLayout'
import { sortByPosition } from '../../pages/dis/helpers'

export const MY_MENU_KEY = 'my-menus'
export const ALL_MENU_KEY = 'all-menus'
export const RIGHT_SIDE_MENU_LIST = [
	{
		name: 'question',
		icon: QuestionMarkIcon,
	},
	{
		name: 'video',
		icon: VideocamIcon,
	},
	{
		name: 'headset',
		icon: HeadsetMicIcon,
	},
	{
		name: 'contruction',
		icon: ConstructionIcon,
	},
	{
		name: 'setting',
		icon: SettingsIcon,
	},
]

const DisLayout = ({ children }) => {
	const { environment } = useSelector((state) => state)
	const [isShowAllMenus, setIsShowAllMenus] = useState(false)
	const [currentMenu, setCurrentMenu] = useState('default')

	const isAdmin = checkAdminRole(environment?.userCurrent)

	const { data, isFetching } = useQuery([MY_MENU_KEY], () => menuApi.getMy(environment.apiToken))

	const { data: allMenus, isFetching: isFetchingAll } = useQuery(
		[ALL_MENU_KEY, isAdmin],
		() => menuApi.getAll(environment.apiToken),
		{
			enabled: isAdmin,
		}
	)

	const getMenuSettings = useMemo(() => {
		if (!isShowAllMenus) {
			if (data?.data?.isDefault) {
				return RIGHT_SIDE_MENU_LIST.map((item) => item.name)
			}
			return isJson(data?.data?.settings) ? JSON.parse(data?.data?.settings) : []
		} else {
			let settingsList = allMenus?.data?.map((item) =>
				isJson(item?.settings) ? JSON.parse(item?.settings) : []
			)

			const flattenedList = _.flatMap(settingsList)
			const uniqueList = _.uniq(flattenedList)

			return uniqueList
		}
	}, [data?.data, allMenus?.data, isShowAllMenus])

	const menuList = useMemo(() => {
		try {
			if (!isShowAllMenus) {
				return data?.data?.items ?? []
			} else {
				const menuItems = allMenus?.data?.filter(
					(item) => !item.isDefault && item.key === currentMenu
				)
				if (menuItems?.length > 0) {
					return (
						menuItems?.flatMap((item) => {
							return item?.items?.map((subItem) => ({ ...subItem, menuKey: item.key }))
						}) ?? []
					)
				}
				return []
			}
		} catch {
			return []
		}
	}, [data?.data, allMenus?.data, isShowAllMenus, currentMenu])

	const sortedMenuList = useMemo(() => {
		return sortByPosition(menuList)
	}, [menuList])

	return (
		<>
			<NavLayout />
			<DisNavTabs
				menuItemList={sortedMenuList}
				isShowAllMenus={isShowAllMenus}
				setIsShowAllMenus={setIsShowAllMenus}
				isShowIconLoading={isFetching || isFetchingAll}
				getMenuSettings={getMenuSettings}
				setCurrentMenu={setCurrentMenu}
				listAllMenu={allMenus?.data ?? []}
			/>
			{children}
		</>
	)
}

export default DisLayout
