import { createContext, useState } from 'react'

export const CoreContext = createContext()

export const CoreProvider = (props) => {
	const { values } = props
	const [coreValues, setCoreValues] = useState(values)

	return (
		<CoreContext.Provider value={{ coreValues, setCoreValues }}>
			{props.children}
		</CoreContext.Provider>
	)
}
