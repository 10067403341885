export const REPORTS_ACTIONS = {
	ACTION: 'REPORTS_ACTION',
	REPORT_SELECTED: 'REPORTS_REPORT_SELECTED',
	RESET: 'REPORTS_RESET',
	REPORT_RESTORE_PRODUCER: 'REPORTS_REPORT_RESTORE_PRODUCER',
	TAB_CHANGE: 'REPORTS_TAB_CHANGE',
	NAME: 'Reports',
}

const initialState = {
	action: null,
	reportSelected: {},
}

const reportsReducer = (state = initialState, action) => {
	switch (action.type) {
		case REPORTS_ACTIONS.ACTION: {
			return {
				...state,
				action: action.payload,
			}
		}
		case REPORTS_ACTIONS.REPORT_SELECTED: {
			return {
				...state,
				reportSelected: action.payload,
			}
		}
		case REPORTS_ACTIONS.RESET:
			return initialState
		case REPORTS_ACTIONS.REPORT_RESTORE_PRODUCER: {
			return {
				...state,
				action: action.payload.action,
				reportSelected: action.payload.reportSelected,
			}
		}
		case REPORTS_ACTIONS.TAB_CHANGE: {
			if (REPORTS_ACTIONS.NAME === action.payload) {
				return state
			} else {
				return {
					...state,
					reportSelected: {},
				}
			}
		}
		default:
			return state
	}
}

export default reportsReducer
