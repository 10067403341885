import axios from 'axios'
import { apiUrl } from '../apiUrl'

const url = apiUrl()
const apiHeader = (token) => {
	return {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}
}

const menuApi = {
	getAll(token) {
		return axios.get(`${url}/menus`, {
			headers: apiHeader(token),
		})
	},

	getMy(token) {
		return axios.get(`${url}/menus/my`, {
			headers: apiHeader(token),
		})
	},

	get({ id, token, cancelToken }) {
		return axios.get(`${url}/menus/${id}`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},

	add({ data, token }) {
		return axios.post(`${url}/menus`, data, {
			headers: apiHeader(token),
		})
	},

	update({ id, newData, token }) {
		return axios.put(`${url}/menus/${id}`, newData, {
			headers: apiHeader(token),
		})
	},

	remove({ id, token }) {
		return axios.delete(`${url}/menus/${id}`, {
			headers: apiHeader(token),
		})
	},
}

export default menuApi
