import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import {
	Collapse,
	FormControl,
	List,
	ListItemButton,
	ListItemText,
	Popover,
	TextField,
	Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { shouldShowDefaultFormSelector } from 'utils/functions/helpers'
import { updateUserCurrent } from '../components/core/services/environmentService'
import { getAllProjects, getProjectForms } from '../components/data/dataServices'
import { ENV_ACTIONS } from '../reducers/environmentReducer'
import DoformsMessage from './DoformsMessage'
import LoadingSpinner from './LoadingSpinner'

const useStyles = makeStyles(() => ({
	root: {
		'label + &': {
			marginTop: '16px',
		},
		'& .MuiOutlinedInput-input': {
			cursor: 'pointer',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			'& legend': {
				display: 'none',
			},
		},
	},

	devicesDisplayRoot: {
		'label + &': {
			marginTop: '16px',
		},
		'& .MuiOutlinedInput-input': {
			cursor: 'pointer',
		},
	},
}))

const initValues = {
	projectKey: null,
	formKey: null,
}

const DoformsDefaultForm = ({ tab, source }) => {
	const [t] = useTranslation('common')
	const environment = useSelector((state) => state.environment)
	const permissions = environment?.userCurrent?.rights

	const classes = useStyles()

	const width = '400px'

	const [anchorEl, setAnchorEl] = useState(null)
	const isSelectOpen = Boolean(anchorEl)

	const [collapseProjects, setCollapseProjects] = useState([])
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [defaultProjectForm, setDefaultProjectForm] = useState(initValues)

	const [dispatchForm, setDispatchForm] = useState(initValues)
	const [deviceAuditForm, setDeviceAuditForm] = useState(initValues)

	const dispatch = useDispatch()
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		return () => (isMounted.current = false)
	}, [])

	useEffect(() => {
		if (!tab) return
		if (!environment.isProjectFormsLoaded) {
			initiateLoadAllProjects()
		}
	}, [tab])

	const initiateLoadAllProjects = () => {
		setLoading(true)
		dispatch({
			type: ENV_ACTIONS.IS_LOADING_PROJECTS,
			payload: true,
		})
		loadAllProjects()
			.then((res) => {
				const projects = _.sortBy(res.data, 'name')
				initiateLoadFormsByProject(projects)
			})
			.catch((err) => {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			})
			.finally(() => {
				dispatch({
					type: ENV_ACTIONS.IS_PROJECT_FORMS_LOADED,
					payload: true,
				})
			})
	}

	const initiateLoadFormsByProject = (projects) => {
		loadFormsByProject(projects)
			.then((res) => {
				const newProjects = _.sortBy(res, 'name')
				dispatch({
					type: ENV_ACTIONS.SET_NEW_PROJECTS,
					payload: newProjects,
				})
			})
			.catch((err) => {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			})
			.finally(() => {
				if (isMounted.current) {
					setLoading(false)
				}
				dispatch({
					type: ENV_ACTIONS.IS_LOADING_PROJECTS,
					payload: false,
				})
			})
	}

	const loadAllProjects = async () => {
		let promise = await getAllProjects(environment.apiToken)
		return promise
	}

	const loadFormsByProject = async (projects) => {
		let promises = []
		for (let i = 0; i < projects.length; i++) {
			const promise = getProjectForms(projects[i].key, environment.apiToken).then((resp) => {
				let promiseObject = { key: projects[i].key, name: projects[i].name, forms: [] }
				if (resp?.data && _.isArray(resp.data) && resp.data.length) {
					promiseObject.forms = _.sortBy(resp.data, 'name')
					dispatch({
						type: ENV_ACTIONS.GET_FORMS,
						payload: promiseObject.forms,
					})
				}
				return promiseObject
			})
			promises.push(promise)
		}

		return await Promise.all(promises)
	}

	const projectForms = useMemo(() => {
		if (!environment.projects) return []
		return [...environment.projects]
	}, [environment.projects])

	const currentDefaultForm = useMemo(() => {
		if (!environment.projects.length) return '...'
		if (!environment.userCurrent?.projectForm) return t('common:misc.noDefaultFormDelected')
		setDefaultProjectForm({ ...environment.userCurrent.projectForm })

		const matchProject = environment.projects.find(
			(item) => item.key === environment.userCurrent.projectForm.projectKey
		)
		if (matchProject) {
			const matchForm = matchProject.forms.find(
				(item) => item.key === environment.userCurrent.projectForm.formKey
			)
			return matchProject && matchForm
				? `${matchProject.name} / ${matchForm.name}`
				: t('common:misc.noProjectFormsLoaded')
		} else {
			return t('common:misc.noProjectFormsLoaded')
		}
	}, [environment.userCurrent, environment.projects])

	const currentDispatchForm = useMemo(() => {
		if (!environment.isProjectFormsLoaded) return '...'
		if (!environment.projects.length) return '...'
		if (_.isEmpty(environment.dispatchForm)) return t('common:misc.noDispatchFormDelected')
		setDispatchForm({ ...environment.dispatchForm })

		const matchProject = environment.projects.find(
			(item) => item.key === environment.dispatchForm.projectKey
		)
		if (!matchProject?.forms.length) return '...'
		const matchForm = matchProject?.forms.find(
			(item) => item.key === environment.dispatchForm.formKey
		)
		return matchProject && matchForm
			? `${matchProject.name} / ${matchForm.name}`
			: t('common:misc.noDispatchProjectFormsLoaded')
	}, [environment.dispatchForm, environment.projects])

	const currentDeviceAuditForm = useMemo(() => {
		if (!environment.isProjectFormsLoaded) return '...'
		if (!environment.projects.length) return '...'
		// if (_.isEmpty(environment.deviceAuditForm)) return "No form selected";
		if (_.isEmpty(environment.deviceAuditForm)) {
			if (!environment.userCurrent?.projectForm) return t('common:misc.noFormSelected')
			setDeviceAuditForm({ ...environment.userCurrent.projectForm })
			dispatch({
				type: ENV_ACTIONS.DEVICE_AUDIT_FORM,
				payload: { ...environment.userCurrent.projectForm },
			})
			return currentDefaultForm
		}

		setDeviceAuditForm({ ...environment.deviceAuditForm })

		const matchProject = environment.projects.find(
			(item) => item.key === environment.deviceAuditForm.projectKey
		)
		if (!matchProject?.forms.length) return '...'
		const matchForm = matchProject?.forms.find(
			(item) => item.key === environment.deviceAuditForm.formKey
		)
		return matchProject && matchForm
			? `${matchProject.name} / ${matchForm.name}`
			: t('common:misc.noDispatchProjectFormsLoaded')
	}, [environment.deviceAuditForm, environment.projects])

	const showLoading = () => (loading || environment.isLoadingProjects) && <LoadingSpinner />

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setError(null)
	}

	const handleSelectOpen = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleSelectClose = () => {
		setAnchorEl(null)
	}

	const handleCollapsed = (projectKey) => {
		const found = collapseProjects.includes(projectKey)
		if (!found) {
			setCollapseProjects([...collapseProjects, projectKey])
		} else {
			setCollapseProjects(collapseProjects.filter((item) => item !== projectKey))
		}
	}

	const handleFormSelection = (projectKey, formKey) => {
		let projectForm = {
			projectKey: projectKey,
			formKey: formKey,
		}
		let newUserCurrentObj = {
			...environment.userCurrent,
			projectForm: projectForm,
		}

		if (tab === 'navigation') {
			setLoading(true)
			updateUserCurrent(newUserCurrentObj, environment.apiToken)
				.then((res) => {
					dispatch({
						type: ENV_ACTIONS.UPDATE_DEFAULT_FORM,
						payload: newUserCurrentObj,
					})
				})
				.catch((err) => {
					setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
				})
				.finally(() => {
					setLoading(false)
				})
		} else {
			switch (source) {
				case 'button':
					dispatch({
						type: ENV_ACTIONS.DISPATCH_FORM,
						payload: projectForm,
					})
					break
				default:
					dispatch({
						type: ENV_ACTIONS.DEVICE_AUDIT_FORM,
						payload: projectForm,
					})
					break
			}
			handleSelectClose()
		}
	}

	const isProjectFormSame = (project, form, defaultProjectForm) => {
		const currentProjectForm = {
			projectKey: project.key,
			formKey: form.key,
		}
		const match = _.isEqual(currentProjectForm, defaultProjectForm)
		return match
	}

	const renderSelection = () => (
		<Popover
			anchorEl={anchorEl}
			id={'my-default-form-container'}
			keepMounted
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			open={isSelectOpen}
			onClose={handleSelectClose}
			PaperProps={{
				style: { width: width, maxWidth: '45vw', maxHeight: '60vh' },
			}}
		>
			<List
				component="nav"
				dense={true}
				aria-labelledby="nested-list-subheader"
				onMouseDown={(event) => event.stopPropagation()}
			>
				{projectForms.map((project, index) => (
					<div key={project.key}>
						<ListItemButton
							sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
							onClick={() => handleCollapsed(project.key)}
						>
							<ListItemText>
								<Typography variant={'h6'} fontSize={16}>
									{project.name}
								</Typography>
							</ListItemText>
							{!collapseProjects.includes(project.key) ? <ArrowDropUp /> : <ArrowDropDown />}
						</ListItemButton>
						<Collapse
							id={index}
							in={!collapseProjects.includes(project.key)}
							timeout="auto"
							unmountOnExit
						>
							{project.forms.map((form) => {
								if (tab === 'navigation' && form.abandoned) return null
								return (
									<ListItemButton
										key={form.key}
										sx={{
											pl: 4,
											pointerEvents: isProjectFormSame(
												project,
												form,
												tab === 'navigation'
													? defaultProjectForm
													: source === 'grid'
													? currentDeviceAuditForm
													: currentDispatchForm
											)
												? 'none'
												: 'auto',
										}}
										selected={isProjectFormSame(
											project,
											form,
											tab === 'navigation'
												? defaultProjectForm
												: source === 'grid'
												? currentDeviceAuditForm
												: currentDispatchForm
										)}
										onClick={() => handleFormSelection(project.key, form.key)}
									>
										<ListItemText>
											<Typography variant={'body1'} fontSize={14}>
												{form.name}
											</Typography>
										</ListItemText>
									</ListItemButton>
								)
							})}
						</Collapse>
					</div>
				))}
			</List>
		</Popover>
	)

	const showFieldForNavigation = () => {
		return (
			<>
				<Typography variant={'subtitle1'}>{t('common:misc.defaultForm')}</Typography>
				<TextField
					id="select-default-form"
					size="small"
					onClick={handleSelectOpen}
					value={loading ? t('common:filters.loading') : currentDefaultForm}
					title={loading ? t('common:filters.loading') : currentDefaultForm}
					fullWidth
					InputProps={{
						readOnly: true,
						endAdornment: isSelectOpen ? (
							<>
								{loading ? (
									<LoadingSpinner withStyle={false} size={15} />
								) : (
									<ArrowDropUp position="end" />
								)}
							</>
						) : (
							<>
								{loading ? (
									<LoadingSpinner withStyle={false} size={15} />
								) : (
									<ArrowDropDown position="end" />
								)}
							</>
						),
					}}
				/>
			</>
		)
	}

	const showFieldForDevices = () => {
		return (
			<TextField
				id="select-default-form"
				size="small"
				onClick={handleSelectOpen}
				value={source === 'grid' ? currentDeviceAuditForm : currentDispatchForm}
				title={source === 'grid' ? currentDeviceAuditForm : currentDispatchForm}
				label={source === 'grid' ? t('common:misc.form') : t('common:misc.dispatchForm')}
				fullWidth
				InputProps={{
					readOnly: true,
					endAdornment: isSelectOpen ? (
						<>
							{loading ? (
								<LoadingSpinner withStyle={false} size={15} />
							) : (
								<ArrowDropUp position="end" />
							)}
						</>
					) : (
						<>
							{loading ? (
								<LoadingSpinner withStyle={false} size={15} />
							) : (
								<ArrowDropDown position="end" />
							)}
						</>
					),
				}}
			/>
		)
	}

	return (
		<>
			{showLoading()}
			{showErrorMessage()}
			<FormControl
				sx={{ mt: 1, mb: 1 }}
				variant="outlined"
				fullWidth
				className={tab === 'navigation' ? classes.root : classes.devicesDisplayRoot}
			>
				{tab === 'navigation' &&
					shouldShowDefaultFormSelector(permissions) &&
					showFieldForNavigation()}
				{tab === 'devices' && showFieldForDevices()}
			</FormControl>
			{renderSelection()}
		</>
	)
}

export default DoformsDefaultForm
